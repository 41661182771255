<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="8">
            <h4 id="traffic" class="card-title mb-0">List NDA Status</h4>
            <div class="small text-muted">{{date(new Date(), 'MMMM YYYY')}}</div>
          </CCol>
          <CCol sm="4" class="col align-self-center row justify-content-end">
            <CButton
							class="my-2 text-center"
							color="danger"
							variant="outline"
							square
							size="sm"
							@click="generatePdf"
						>
							Generate PDF
						</CButton> &nbsp;
				    <download-excel
              class="btn btn-default"
              :data="filteredItems"
              :fields="jsonFields"
              worksheet="Sheet1"
              name="list-nda-status.xls"
            >
              <CButton
                color="success"
                variant="outline"
                square
                size="sm"
              >
                Download Excel
              </CButton>
            </download-excel>
			    </CCol>
        </CRow>

        <CRow class="mt-5">
          <CCol sm="3">
						<CInput
							type="text"
							label="Search.."
							placeholder=""
							description="Masukkan kata kunci pencarian."
							v-model="search"
							required
						/>
					</CCol>
					<CCol sm="3">
            <CSelect 
							:value.sync="keyword"
							name="keyword"
							label="Filter By"
							:options="keywords"
						/>
          </CCol>
          <CCol sm="3">
            <CButton
              style="margin-top: 30px;"
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="filterRange"
            >
              Apply
            </CButton> &nbsp;
            <CButton
              style="margin-top: 30px;"
              color="success"
              variant="outline"
              square
              size="sm"
              @click="filterRefresh"
            >
              Refresh List
            </CButton>
          </CCol>
        </CRow>

        <CDataTable
					hover
					striped
					border
					small
					sorter
					:items="filteredItems"
					:fields="fields"
          :items-per-page="perPage"
				  pagination
					:loading="isLoading"
				>
					<template #id="{item}">
						<td align="center">{{filteredItems.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
					</template>
          <template #id_card_number="{item}">
            <td align="right">{{item.id_card_number === null ? '' : item.id_card_number}}</td>
          </template>
          <template #created_at="{item}">
            <td align="right">{{item.expired_at === null ? '' : date(item.created_at, 'DD MMM YYYY HH:mm')}}</td>
          </template>
          <template #expired_at="{item}">
            <td align="right">{{item.expired_at === null ? '' : date(item.expired_at, 'DD MMM YYYY HH:mm')}}</td>
          </template>
          <template #action="{item}">
						<td align="center">
							<a v-if="item.expired_at !== null" style="cursor: pointer; color: green;" @click="preview(item.user_id, item)">
                <CIcon size="lg" name="cil-cloud-download" />
              </a>
              <a v-if="item.expired_at === null" style="cursor: not-allowed; color: gray;">
                <CIcon size="lg" name="cil-cloud-download" />
              </a>
						</td>
					</template>
				</CDataTable>

        <vue-html2pdf
					:show-layout="true"
					:float-layout="false"
					:enable-download="true"
					:preview-modal="false"
					:paginate-elements-by-height="1400"
					filename="Lintasarta-List-NDA-Status"
					:pdf-quality="2"
					:manual-pagination="true"
					pdf-format="a4"
					pdf-orientation="portrait"
					pdf-content-width="100%"
					@hasStartedGeneration="hasStartedGeneration()"
					@hasGenerated="hasGenerated($event)"
					:html-to-pdf-options="htmlToPdfOptions"
					ref="html2Pdf"
					class="d-none"
				>
          <section slot="pdf-content" id="content">
            <ThePdfHeader title="Report List NDA Status" :reportFrom="date(new Date(), 'DD MMMM YYYY')" :reportTo="date(new Date(), 'DD MMMM YYYY')" :reportFor="reportFor" />

            <CDataTable
					    border
					    small
					    :items="filteredItems"
					    :fields="pdfFields"
              :items-per-page="perPage"
				    >
              <template #id="{item}">
						    <td align="center">{{ filteredItems.map(function(x) {return x; }).indexOf(item)+1 }}</td>
					    </template>
              <template #id_card_number="{item}">
                <td align="right">{{item.id_card_number === null ? '' : item.id_card_number}}</td>
              </template>
              <template #created_at="{item}">
                <td align="right">{{item.expired_at === null ? '' : date(item.created_at, 'DD MMM YYYY HH:mm')}}</td>
              </template>
              <template #expired_at="{item}">
                <td align="right">{{item.expired_at === null ? '' : date(item.expired_at, 'DD MMM YYYY HH:mm')}}</td>
              </template>
            </CDataTable>
          </section>
        </vue-html2pdf>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import http from '@/utils/http-common';
import moment from 'moment';

import VueHtml2pdf from 'vue-html2pdf';
import ThePdfHeader from '@/containers/ThePdfHeader.vue';

export default {
  name: 'ListNdaStatus',
  components: {
    VueHtml2pdf, ThePdfHeader
  },
  data () {
		return {
      company_id: +localStorage.getItem('company_id'),
      role: +localStorage.getItem('role'),
      items: [],
      search: '',
      filteredItems: [],
			fields: [
        { key: 'id', label: 'No' },
        'company_name',
        'name',
        'role',
        'id_card_number',
        'created_at',
        'expired_at',
        'status', 
        'action'
      ],
      pdfFields: [
        { key: 'id', label: 'No' },
        'company_name',
        'name',
        'role',
        'id_card_number',
        'created_at',
        'expired_at',
        'status'
      ],
			currentPage: 1,
			perPage: 15,
			totalRows: 1,
			isLoading: true,
      keyword: 'Company Name',
      keywords: [
        'Company Name', 'Name', 'Role'
      ],
      reportFor: 'Company Name: All',
      jsonFields: {
        No: {
          field: "id",
          callback: (value) => {
            return this.filteredItems.map(function(x) {return x.id; }).indexOf(value)+1;
          },
        },
        "Company Name": 'company_name',
        "Name": 'name',
        "Role": 'role',
        "No KTP": 'id_card_number',
        "Created At": {
          field: "created_at",
          callback: (value) => {
            return this.date(value, 'DD MMMM YYYY');
          },
        },
        "Expired At": {
          field: "expired_at",
          callback: (value) => {
            return this.date(value, 'DD MMMM YYYY');
          },
        },
        "Status": 'status',
      },
      htmlToPdfOptions: {
				margin: 0,
				filename: 'Lintasarta-NDA-Status',
				'pdf-quality': '2',
				jsPDF: {
					unit: 'mm',
					format: 'a4',
					orientation: 'portrait'
				},
				html2canvas: {
					dpi: 300,
					letterRendering: true,
					useCORS: true
				}
			},
    }
  },
  methods: {
    generatePdf () {
			this.$refs.html2Pdf.generatePdf();
		},
    getData() {
			let self = this;
			return http.get('/ndas')
				.then(function (response) {
          let now = new Date();
          let list = response.data.data;

          if (self.role === 4 || self.role > 5) {
            list = list.filter(function(item) {
    				  return item.company_id === self.company_id;  
					  });
          }

          list.forEach(item => {
            item['status'] = new Date(item.expired_at).getTime() < now.getTime() || item.expired_at === null ? 'Expired' : 'Valid';
          })

					self.items = list;
          self.filteredItems = list;
					self.isLoading = false;
				}).catch(function (error) {
					console.log(error);
				});
		},
    filterRange() {
      let self = this;
      self.filteredItems = [];

      let data = self.items.filter(item => {
        if (self.keyword === 'Name') {
          self.reportFor='Name: ' + self.search.toLowerCase();
          return item.name.toLowerCase().includes(self.search.toLowerCase());
        } else if (self.keyword === 'Company Name') {
          self.reportFor='Company Name: ' + self.search.toLowerCase();
          return item.company_name.toLowerCase().includes(self.search.toLowerCase());
        } else {
          self.reportFor='Role Name: ' + self.search.toLowerCase();
          return item.role.toLowerCase().includes(self.search.toLowerCase());
        }
      })

      self.filteredItems = data;
    },
    filterRefresh() {
      this.filteredItems = this.items;
      this.reportFor = 'Company Name: All';
      this.search = '';
    },
    date(value, format) {
      return moment(String(value)).locale('Id').format(format);
    },
    preview(uid, data) {
      let self = this;
      self.$store.dispatch('ndaDetail', data).then(() => {
        self.$router.push({ path: '/reports/print-nda/' + uid });
      });
    },
  },
  mounted: function(){
		this.getData();
	}
}
</script>

<style scoped>
#content {
  background: #fff;
  font-size: 7.5px;
  padding: 12px;
}
</style>
